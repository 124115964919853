<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
    <div class="page-title text-center px-5">
      <div>
        <v-icon size="80">
          {{ icons.mdiLockOutline }}
        </v-icon>
      </div>
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">Verifique su código de activación</span>
      </h2>
      <p class="text-sm">
        Usted ha recibido un correo con el código a verificar
      </p>

      <div class="d-flex justify-center my-10">
        <div
          class="ma-auto"
          style="max-width: 300px"
        >
          <v-otp-input
            v-model="otp"
            length="6"
          ></v-otp-input>
        </div>
        <v-overlay
          absolute
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-overlay>
        <v-img
          v-if="false"
          max-width="700"
          src="@/assets/images/3d-characters/error.png"
        ></v-img>
      </div>

      <div>
        <v-btn
          color="primary"
          class="mb-4"
          @click="isValidUUID"
        >
          Aceptar
        </v-btn>

        <v-btn
          color="gray"
          class="mb-4"
          plain
          @click="forwardOtp"
        >
          Reenviar código
        </v-btn>
      </div>
    </div>
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiLockOutline } from '@mdi/js'
import { ref, reactive } from '@vue/composition-api'
import { useRouter } from '@/utils'
import HTTP from '@/utils/axios/axios-config-base'

export default {
  // _, context
  setup() {
    const { router, route } = useRouter()
    const otp = ref('')

    // const { $store } = context.root
    // const { provider, providerId, code } = $store.state.auth.data
    const localStorageParams = ref(false)
    const routerParams = ref(false)
    const { providerId, code } = route.value.params
    if (localStorage.getItem('code') && localStorage.getItem('providerId')) {
      localStorageParams.value = true
    }
    if (providerId && code) {
      routerParams.value = true
    }
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })

    const isParamsValid = async () => {
      if (!localStorageParams.value && !routerParams.value) {
        router.replace({ name: 'error-404' })
      }
    }

    isParamsValid()

    const isValidUUID = async () => {
      // Se reciben los parametros y se comprueba que dichos parametros sean correctos
      // Si son correctos se recibe el token
      // Una vez recibido el token se guarda en el locaStorage
      if (localStorageParams.value || routerParams.value) {
        const objTemp = {
          operationCode: code,
          provider: 'backoffice',
          providerId,
          otpCode: otp.value,
        }
        try {
          const resp = await HTTP.post('identity-api/manager/login', objTemp)
          localStorage.clear()
          localStorage.setItem('token', resp.data.jwt)

          const URL = `${HTTP.defaults.baseURL}identity-api/user/me`
          const resp1 = await HTTP.get(URL)
          localStorage.setItem('permissions', resp1.data.permissions)

          router.replace({ name: 'dashboard' })
        } catch (error) {
          notificaciones.color = 'error'
          notificaciones.text = 'Código OTP incorrecto'
          notificaciones.snackbar = true
        }
      } else {
        router.replace({ name: 'error-404' })
      }
    }

    const forwardOtp = async () => {
      router.replace({ name: 'dashboard' })
    }

    return {
      otp,
      loading: ref(false),
      notificaciones,
      localStorageParams,
      routerParams,
      icons: {
        mdiLockOutline,
      },
      isValidUUID,
      isParamsValid,
      forwardOtp,
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
